import { css } from "@emotion/react";
import styled from "@emotion/styled";
import loadingMojo from "@src/assets/images/loadingMojo.gif";
import lockedOutMojo from "@src/assets/images/login/locked_out.png";
import { AppDataContext } from "@src/components/AppDataContext";
import Checkbox from "@src/components/forms/Checkbox";
import CodeVerification from "@src/components/modals/LoginCode/CodeVerification";
import { OrSeparator } from "@src/components/OrSeparator";
import { Text } from "@src/components/Text";
import Translate from "@src/components/translation/Translate";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { ActivationRedirectionContext } from "@src/contexts/ActivationRedirectionContext";
import { logEvent as le, logEvent, setEntityId } from "@src/utils/logClient";
import axios from "axios";
import { useFormik } from "formik";
import window from "global/window";
import _get from "lodash/get";
import React, { useContext, useEffect, useState } from "react";
import { color, layout, space } from "styled-system";
import { Box, Flex } from "../Boxes";
import Button from "../Button";
import Input from "../forms/Input";
import LoginWithCodeButton from "../LoginWithCodeButton";
import { Space, Subheading, theme } from "../nessie-web";
import LoginCodeContainer from "./LoginCode/LoginCodeContainer";
import { ModalContext, ModalType } from "./ModalController";
import * as yup from "yup";
import { isProduction } from "@src/utils/env";
import { useFeatureFlag } from "@src/utils/useFeatureFlag";
import { ExternalSwitches } from "@src/utils/experiments/constants";

const {
  colors: { dt_taro10, dt_taro50, dt_taro80 },
} = theme;

const ErrMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

const ExistingEmailAlert = styled.div`
  background-color: ${(props) => props.theme.__new.colors.backgroundSecondary};
  border-radius: 10px;
  padding: 24px;
  font-size: 18px;
`;

const Link = styled.a<{ hoverColor?: string; ml?: `${number}px`; display?: "inline" }>`
  display: inline-block;
  line-height: 18px;
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: ${theme.colors.contentAccent};
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${theme.colors.contentAccentHover};
  }

  ${layout}
  ${space}
  ${color}
`;

const Label = styled.label`
  font-weight: 600;
  color: ${dt_taro80};
  margin-bottom: 6px;
  display: block;
`;

const LinkButton = styled.div<{ hoverColor?: string; mb?: `${number}px`; mt?: `${number}px`; ml?: `${number}px` }>`
  display: inline-block;
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: ${theme.colors.contentAccent};
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${theme.colors.contentAccentHover};
  }
  ${space}
`;

const StyledForm = styled("form")`
  width: 100%;
`;

const SignToWatchWrapper = styled("div")`
  background-color: ${dt_taro10};
  padding: 16px;
  text-align: center;
`;

type ApiAnswerState =
  | {
      code: "success" | "api-error" | "auth-error" | "auth-lockout" | "auth-suspended" | null;
    }
  | {
      code: "forced-otc";
      detail: {
        message: string;
      };
    };

const AuthErrorResponse = ({ remainingAttempts }: { remainingAttempts: number | null }) => {
  if (remainingAttempts === null) return null;

  const loginAttemptsCopy =
    remainingAttempts === 1 ? "components.login.one_attempt" : "components.login.num_of_attempts";
  if (remainingAttempts <= 5)
    return (
      <>
        <Text fontSize="2" fontWeight="bold" color="#2C2A50">
          <Translate path={loginAttemptsCopy} subs={{ attempts: remainingAttempts.toString() }} />
        </Text>
        <Text fontSize="2">
          <Translate path="components.login.lockout_warning" />
        </Text>
      </>
    );

  return (
    <>
      <Text fontSize="2" display="inline" role="alert" aria-live="polite">
        <Translate path="components.login.invalid_email_or_password" />
      </Text>
    </>
  );
};

const ApiAnswerDetails = ({
  apiAnswer,
  remainingAttempts,
}: {
  apiAnswer: ApiAnswerState;
  remainingAttempts: number | null;
}) => {
  if (apiAnswer.code === null) return null;

  const colorCss =
    apiAnswer.code === "success"
      ? css`
          background-color: #dff0d8;
          border-color: #d6e9c6;
          color: #3c763d;
          border-radius: 5px;
        `
      : css`
          background-color: #ffecee;
          color: #d72a2b;
          border-radius: 30px;
          font-weight: 600;
        `;
  return (
    <Box my={3} p={3} textAlign="center" css={colorCss}>
      {apiAnswer.code === "success" && (
        <Text fontSize="2">
          <Translate path="components.login.login_success" />
        </Text>
      )}
      {apiAnswer.code === "api-error" && (
        <>
          <Text fontSize="2" display="inline">
            <Translate path="components.login.login_error" />
          </Text>
          <Link ml="5px" href="https://status.classdojo.com" color="#00bcf2" hoverColor="#00a8d9" display="inline">
            <Translate path="components.login.check_status_page" />
          </Link>
        </>
      )}
      {apiAnswer.code === "auth-error" && <AuthErrorResponse remainingAttempts={remainingAttempts} />}
      {apiAnswer.code === "auth-suspended" && (
        <Text fontSize="2" display="inline" role="alert" aria-live="polite">
          <Translate path="components.login.suspended_user" />
        </Text>
      )}
    </Box>
  );
};

const GoogleLoginButton = () => {
  const t = useContext(TranslationContext);
  const emailFirstLoginExperimentVariant = useFeatureFlag(ExternalSwitches.WEB_EXTERNAL_PARENT_EMAIL_FIRST_LOGIN_V2);
  if (emailFirstLoginExperimentVariant.actualValue !== "test") {
    return null;
  }

  return (
    <Button
      big
      width="100%"
      my="5px"
      outline
      style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}
      onClick={(e) => {
        e.preventDefault();
        logEvent({
          eventName: "web.login.google_login_button_click",
          experiments: [ExternalSwitches.WEB_EXTERNAL_PARENT_EMAIL_FIRST_LOGIN_V2],
        });
        const host = isProduction() ? "https://home.classdojo.com" : "https://home.classdojo.test:8085";
        fetch(`${host}/api/parentGoogleLoginUrl`, {
          credentials: "include",
        })
          .then((response) => response.json())
          .then((json) => {
            const { url } = json;
            window.location = url;
          });
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.14 12.2044C21.14 11.5663 21.0827 10.9526 20.9764 10.3635H12.5V13.8449H17.3436C17.135 14.9699 16.5009 15.9231 15.5477 16.5613V18.8194H18.4564C20.1582 17.2526 21.14 14.9453 21.14 12.2044Z"
          fill="#4285F4"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5 21C14.93 21 16.9673 20.1941 18.4564 18.8195L15.5477 16.5613C14.7418 17.1013 13.7109 17.4204 12.5 17.4204C10.1559 17.4204 8.17182 15.8372 7.46409 13.71H4.45728V16.0418C5.93818 18.9831 8.98182 21 12.5 21Z"
          fill="#34A853"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.46409 13.7099C7.28409 13.1699 7.18182 12.5931 7.18182 11.9999C7.18182 11.4068 7.28409 10.8299 7.46409 10.2899V7.95813H4.45727C3.84773 9.17313 3.5 10.5477 3.5 11.9999C3.5 13.4522 3.84773 14.8268 4.45727 16.0418L7.46409 13.7099Z"
          fill="#FBBC05"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5 6.57948C13.8214 6.57948 15.0077 7.03358 15.9405 7.92539L18.5218 5.34403C16.9632 3.89176 14.9259 2.99994 12.5 2.99994C8.98182 2.99994 5.93818 5.01676 4.45728 7.95812L7.46409 10.2899C8.17182 8.16267 10.1559 6.57948 12.5 6.57948Z"
          fill="#EA4335"
        />
      </svg>
      {t.translate("components.login.combined_signup.continue_with_google")}
    </Button>
  );
};

type LoginFormData = {
  login: string;
  password: string;
  longSession: boolean;
};
type LoginFormProps = {
  userType: "teacher" | "parent" | "leader";
  noRedirect?: boolean;
  closeModal?: () => void;
  signToWatch?: string;
  emailPrefill?: string;
  showCodeLogin?: boolean;
  disableHeader?: (disable: boolean) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({
  userType,
  noRedirect,
  closeModal,
  signToWatch,
  emailPrefill,
  showCodeLogin,
  disableHeader,
}) => {
  const t = useContext(TranslationContext);
  const {
    data: { geolocation },
  } = useContext(AppDataContext);
  const modalContext = useContext(ModalContext);
  const appData = useContext(AppDataContext);
  const redirectionContext = useContext(ActivationRedirectionContext);

  function showSignupModal() {
    modalContext.showModal(ModalType.Signup);
  }

  useEffect(() => {
    le({
      eventName: "web.login.screen_view",
      metadata: {
        current_site: "external",
        account_type: userType,
      },
    });
  }, [userType]);

  const [apiAnswer, setApiAnswer] = useState<ApiAnswerState>({ code: null });
  const [remainingAttempts, setRemainingAttempts] = useState<number | undefined>(undefined);
  const isUserParent = userType === "parent";
  const defaultLongSessionValue = isUserParent ? !(geolocation && geolocation.state === "ny") : true;

  useEffect(() => {
    le({
      eventName: "web.external_page.login.detected_state",
      eventValue: geolocation && geolocation.state,
    });
  }, []);

  const form = useFormik<LoginFormData>({
    initialValues: { login: emailPrefill ?? "", password: "", longSession: defaultLongSessionValue },
    validationSchema: yup.object().shape({
      login: yup
        .string()
        .required(t.translate("layouts.login.login_required").toString())
        .email(t.translate("layouts.login.login_valid_emal").toString()),
      password: yup.string().required(t.translate("layouts.login.password_required").toString()),
    }),
    onSubmit: ({ login, password, longSession }, { setSubmitting }) => {
      const baseUrl =
        process.env.GATSBY_BUILD_WEBSITE === "ideas"
          ? process.env.GATSBY_IDEAS_API_URL
          : userType === "teacher"
          ? process.env.GATSBY_TEACHER_LOGIN_URL
          : process.env.GATSBY_DEFAULT_LOGIN_URL;
      const url = `${baseUrl}/api/session?duration=${longSession ? "long" : "short"}`;
      axios
        .post(
          url,
          {
            login,
            password,
            resumeAddClassFlow: !!window?.location?.href?.match(/\/connect/),
          },
          {
            withCredentials: true,
          },
        )
        .then((response: any) => {
          setApiAnswer({ code: "success" });
          const { data } = response;
          const entity = data.teacher || data.parent || data.student;
          if (entity) {
            setEntityId(entity._id);
          }

          le({
            eventName: "web.login.success",
            entityId: entity?._id,
            metadata: {
              current_site: "external",
              account_type: userType,
            },
          });

          if (!redirectionContext.getRedirectionStatus()) {
            appData.services.getSession();
            redirectionContext.runSuccessAction();
            modalContext.hideModal();
          } else if (noRedirect) {
            appData.services.getSession();
            modalContext.hideModal();
          } else {
            let redirectUrl = process.env.GATSBY_HOME_URL;
            if (response.data.type === "teacher") redirectUrl = process.env.GATSBY_TEACH_URL;
            else if (response.data.type === "student") redirectUrl = process.env.GATSBY_STUDENT_URL;
            window.location.href = redirectUrl ?? "";
          }
        })
        .catch((error) => {
          const loginAttemptsLeft = _get(error, "response.headers.remaining-attempts");
          le({
            eventName: "web.login.failure",
            metadata: {
              current_site: "external",
              account_type: userType,
            },
          });
          //@ts-expect-error This kind of expression is always falsy.ts(2873)
          setRemainingAttempts(loginAttemptsLeft === (null || undefined) ? undefined : parseInt(loginAttemptsLeft, 10));
          const errorDetail = _get(error, "response.data.error.detail");
          const errorCode = _get(error, "response.data.error.code");
          const fallbackMessage = _get(error, "response.data.error.fallbackMessage");
          if (errorDetail === "Incorrect password" || errorDetail === "Incorrect username") {
            setApiAnswer({ code: "auth-error" });
          } else if (errorDetail === "Too many login attempts, user is temporarily locked out of login") {
            setApiAnswer({ code: "auth-lockout" });
          } else if (errorDetail === "User is suspended") {
            setApiAnswer({ code: "auth-suspended" });
          } else if (errorCode?.startsWith("ERR_MUST_USE_OTC")) {
            setApiAnswer({ code: "forced-otc", detail: { message: fallbackMessage } });
          } else if (errorCode === "ERR_COMPROMISED_PASSWORD") {
            if (userType === "parent") {
              window.location.href = `https://home.classdojo.com/#/forceReset?email=${encodeURIComponent(login)}`;
            } else {
              window.location.href = `https://teach.classdojo.com/#/forceReset?email=${encodeURIComponent(login)}`;
            }
          } else {
            const response = _get(error, "response.data");
            logEvent({
              eventName: "login.error.unknown.detail",
              value: errorDetail,
              metadata: { error: errorDetail, response },
            });
            setApiAnswer({ code: "api-error" });
          }
        })
        .then(function () {
          setSubmitting(false);
        });
    },
  });

  function showForgotPasswordModal() {
    le({
      eventName: "web.account_recovery.forgot_password.reset_password_tap",
      metadata: {
        account_type: userType,
        current_site: "external",
      },
    });
    modalContext.showModal(ModalType.ResetPassword, { userType, previousEmail: form.values.login });
  }

  const [loginWithCode, setLoginWithCode] = useState(false);
  const updateLoginView = ({ showLoginWithCode }: { showLoginWithCode: boolean }) => {
    setLoginWithCode(showLoginWithCode);
    disableHeader(showLoginWithCode);
  };

  if (loginWithCode) {
    return (
      <LoginCodeContainer
        email={form.values.login || emailPrefill}
        goBackToLogin={({ email }) => {
          if (email) {
            form.setFieldValue("login", email);
          }
          updateLoginView({ showLoginWithCode: false });
        }}
        isUserParent={userType === "parent"}
        userType={userType}
      />
    );
  }

  if (apiAnswer.code === "auth-lockout") {
    return (
      <Box my={3} p={3} textAlign="center">
        <img alt="" src={lockedOutMojo} />
        <Text fontSize={4} fontWeight={800}>
          <Translate path="components.login.lockout_header" />
        </Text>
        <Text>
          <Translate path="components.login.lockout_desc" />
        </Text>
        <Button onClick={closeModal} width="100%">
          <Translate path="components.login.lockout_button" />
        </Button>
      </Box>
    );
  } else if (apiAnswer.code === "forced-otc") {
    return (
      <CodeVerification
        userType={userType}
        email={form.values.login}
        password={form.values.password}
        isUserParent={userType === "parent"}
        headerText={apiAnswer.detail.message}
      />
    );
  }

  return (
    <StyledForm onSubmit={form.handleSubmit} data-test-name="login-form">
      {signToWatch && (
        <>
          <SignToWatchWrapper>
            <Subheading color={dt_taro50}>{signToWatch}</Subheading>
          </SignToWatchWrapper>
          <Space size="m" />
        </>
      )}

      {form.isSubmitting && (
        <Box width="50px" height="50px" mb={4} mx="auto">
          <img alt="Loading mojo" src={loadingMojo}></img>
        </Box>
      )}
      <div css={{ gap: "15px", display: "flex", flexDirection: "column" }}>
        <div>
          <Label htmlFor="email">{t.translate("components.common.email_address").toString()}</Label>
          <Input
            id="email"
            aria-label={t.translate("components.common.email_address") as string}
            autoComplete="email"
            required
            name="login"
            onChange={form.handleChange}
            value={form.values.login}
            success={!form.errors.login && form.touched.login}
            error={!!form.errors.login && form.touched.login}
            onBlur={form.handleBlur}
            aria-describedby={form.errors.login && form.touched.login ? "login-error" : undefined}
          />
          <ErrMessage id="login-error" role="alert" aria-live="polite">
            {form.errors.login && form.touched.login && form.errors.login}
          </ErrMessage>
        </div>
        <div>
          <Label htmlFor="password">{t.translate("components.common.password").toString()}</Label>
          <Input
            id="password"
            aria-label={t.translate("components.common.password") as string}
            autoComplete="off"
            type="password"
            name="password"
            required
            onChange={form.handleChange}
            autoFocus={!!emailPrefill}
            value={form.values.password}
            success={!form.errors.password && form.touched.password}
            error={!!form.errors.password && form.touched.password}
            onBlur={form.handleBlur}
            aria-describedby={form.errors.password && form.touched.password ? "password-error" : undefined}
          />
          <ErrMessage id="password-error" role="alert" aria-live="polite">
            {form.errors.password && form.touched.password && form.errors.password}
          </ErrMessage>
        </div>
      </div>
      <LinkButton
        role="button"
        aria-label="Click here to recover password"
        tabIndex={0}
        onClick={showForgotPasswordModal}
        onKeyDown={(e) => e.key === "Enter" && showForgotPasswordModal()}
        mb="5px"
        mt="5px"
      >
        {t.translate("components.login.forget_password")}
      </LinkButton>
      <ApiAnswerDetails apiAnswer={apiAnswer} remainingAttempts={remainingAttempts ?? null} />
      <Button
        disabled={form.isSubmitting || ((!form.isValid || !form.dirty) && !showCodeLogin)}
        type="submit"
        big
        width="100%"
        my="5px"
      >
        {t.translate("pages.home.log_in")}
      </Button>
      {isUserParent && (
        <Checkbox name="longSession" checked={!!form.values.longSession} onChange={form.handleChange}>
          Keep me logged in
        </Checkbox>
      )}
      {isUserParent && <GoogleLoginButton />}
      <Flex justifyContent="center" alignItems="center" mt="5px">
        <Text fontSize={2}> {t.translate("components.login.dont_have_account")}</Text>
        <LinkButton
          role="button"
          tabIndex={0}
          onClick={showSignupModal}
          onKeyDown={(e) => e.key === "Enter" && showSignupModal()}
          mb="10px"
          ml="5px"
        >
          {t.translate("pages.home.sign_up")}
        </LinkButton>
      </Flex>
      {showCodeLogin && (
        <>
          <OrSeparator />
          <LoginWithCodeButton loginWithCode={() => updateLoginView({ showLoginWithCode: true })} />
        </>
      )}
    </StyledForm>
  );
};

export default LoginForm;
